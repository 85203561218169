import React from 'react';
import './App.css';
import {Button, Card, CardContent} from "@mui/material";
import ImageGallery from 'react-image-gallery';

function App() {
    const IMAGES = [
        {original: 'static/landing-state.png', description: 'SolveLog is a site that allows you to time your Rubik\'s cube solves and save them to your account.'},
        {original: 'static/sign-up.png', description: 'You must sign up for an account using your email address so that we can back up your solve times to that account.'},
        {original: 'static/scramble-settings.png', description: 'There are WCA scrambles for all of the major puzzles. Most of these are random state scrambles.'},
        {original: 'static/timer-settings.png', description: 'Set timer settings that are saved based on the cube type selected for the scrambles.  Inspection time, hide time during solve, and more!'}
        // {original: 'static/timer-settings.png', description: 'You can use the built-in timer using the spacebar on your computer or connect a SpeedStacks timer on Windows (Mac support coming soon) and record your times using that.'}
    ];
    return (
        <div className="main-content">
            <div className={'content-card-container'}>
                <Card className={'content-card'}>
                    <div className={'title-row'}>
                        <h1 className={'title'}>SolveLog</h1>
                        <Button variant={'contained'} color={'primary'} href={'https://app.solvelog.com/'}>Go to
                            Application</Button>
                    </div>
                    <ImageGallery
                        autoPlay={true}
                        showFullscreenButton={false}
                        slideInterval={6000}
                        items={IMAGES}/>
                </Card>
            </div>
        </div>
    );
}

export default App;
