import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import reduxStore from "./redux/reduxStore";
import {createTheme, ThemeProvider} from "@mui/material";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: '#bcf8ec',
            contrastText: '#000000'
        },
        secondary: {
            main: '#051014',
            contrastText: '#FFFFFF',
        },
        success: {
            main: '#BFF7BC',
            contrastText: '#000000'
        },
        warning: {
            main: '#F8EBBA',
            contrastText: '#000000',
        },
        error: {
            main: '#F8BAC6',
            contrastText: '#000000'
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={muiTheme}>
            <Provider store={reduxStore}>
                <App/>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
